import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry } from "@sentry/sveltekit";

const __DSN__ = import.meta.env.VITE_VIEWER_SENTRY_DSN;

if (__DSN__) {
	Sentry.init({
		dsn: __DSN__,
		tracesSampleRate: 1.0,
		// TODO These values are meant for dev/staging.
		// We'll need to change them in production.
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		integrations: [new Sentry.Replay()],
	});
}

// Sample customized error handler. See https://www.npmjs.com/package/@sentry/sveltekit

// const myErrorHandler = (({ error, event }) => {
//   console.error('An error occurred on the client side:', error, event);
// });
// export const handleError = handleErrorWithSentry(myErrorHandler);

export const handleError = handleErrorWithSentry();
